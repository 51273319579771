@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, form, fieldset, table, tr, td, img {
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  font-variant-numeric: lining-nums;
}

input, button, select, textarea, optgroup, option {
  -webkit-font-feature-settings: "lnum";
  -moz-font-feature-settings: "lnum";
  font-feature-settings: "lnum";
  font-variant-numeric: lining-nums;
}

@layer base {

  /* raleway-300 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    src: url('../src/fonts/raleway-v28-latin-300.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../src/fonts/raleway-v28-latin-300.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../src/fonts/raleway-v28-latin-300.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../src/fonts/raleway-v28-latin-300.woff') format('woff'),
      /* Modern Browsers */
      url('../src/fonts/raleway-v28-latin-300.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../src/fonts/raleway-v28-latin-300.svg#Raleway') format('svg');
    /* Legacy iOS */
  }

  /* raleway-regular - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../src/fonts/raleway-v28-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../src/fonts/raleway-v28-latin-regular.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../src/fonts/raleway-v28-latin-regular.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../src/fonts/raleway-v28-latin-regular.woff') format('woff'),
      /* Modern Browsers */
      url('../src/fonts/raleway-v28-latin-regular.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../src/fonts/raleway-v28-latin-regular.svg#Raleway') format('svg');
    /* Legacy iOS */
  }

  /* raleway-600 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../src/fonts/raleway-v28-latin-600.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../src/fonts/raleway-v28-latin-600.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../src/fonts/raleway-v28-latin-600.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../src/fonts/raleway-v28-latin-600.woff') format('woff'),
      /* Modern Browsers */
      url('../src/fonts/raleway-v28-latin-600.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../src/fonts/raleway-v28-latin-600.svg#Raleway') format('svg');
    /* Legacy iOS */
  }

  /* raleway-700 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../src/fonts/raleway-v28-latin-700.eot');
    /* IE9 Compat Modes */
    src: local(''),
      url('../src/fonts/raleway-v28-latin-700.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('../src/fonts/raleway-v28-latin-700.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('../src/fonts/raleway-v28-latin-700.woff') format('woff'),
      /* Modern Browsers */
      url('../src/fonts/raleway-v28-latin-700.ttf') format('truetype'),
      /* Safari, Android, iOS */
      url('../src/fonts/raleway-v28-latin-700.svg#Raleway') format('svg');
    /* Legacy iOS */
  }
}

.max-w-xxs {
  max-width: 16rem;
}

.gradient {
  background-image: linear-gradient(-225deg, #cbbacc 0%, #2580b3 100%);
}

.tox-statusbar__wordcount,
.tox-statusbar__branding {
  display: none !important;
}

input:checked + svg {
  display: block;
}

.listing-description p,
.listing-description blockquote,
.listing-description ul,
.listing-description ol {
  margin: 0.65rem 0;
}

.custom-rte p,
.custom-rte ul,
.custom-rte ol,
.custom-rte blockquote {
  padding: 0.35rem 0;
}

.custom-rte ul,
.custom-rte ol {
  padding-left: 1.85rem;
}

.custom-rte ul li {
  list-style-type: disc;
}

.custom-rte ol li {
  list-style-type: decimal;
}

.custom-rte-editor {
  min-height: 200px !important;
}

.svg-icon-white g {
  stroke: none;
  fill: none;
}

.svg-icon-white g path {
  stroke: #fff;
}

select,
select * {
  box-shadow: none !important;
}

.slick-track {
  display: flex;
  flex-direction: row;
  flex: wrap;
  flex-grow: 1;
}

.slick-slide {
  display: flex;
  flex-direction: row;
  flex: wrap;
  flex-grow: 1;
}

.slick-slide > div {
  flex-grow: 1;
}

.max-h-5000 {
  max-height: 5000px;
}



div.tdBefore {
  display: none;
}

.slick-slide {
  visibility: hidden;
}
.slick-slide.slick-active {
  visibility: visible;
}

@media screen and (max-width: 40em) {
  #settings-table tr {
    border: 0 !important;
  }
}

.select2-selection__input:focus {
  box-shadow: none;
}

.slick-dots {
  bottom: 12px !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
}